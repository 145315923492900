import moment from 'moment';
export const itemDefaultSize = 20;
const toDateTime = value => value ? moment.utc(value).toDate() : '';

const extractWebLoadsToList = state => orders => {
    return orders;
};

export const createSubOrder = (so, originalOrder, childSubOrder) => {
    return {
        ...originalOrder,
        ...{
            orderId: so.orderId,
            parentId: so.parentId,
            subOrderId: so.id,
            originalOrderId: so.orderId,
            dropLocationId: childSubOrder ? childSubOrder?.dropLocationId : originalOrder?.dropLocationId,
            dropLocation: childSubOrder ? childSubOrder?.dropLocation : originalOrder?.dropLocation,
            puDate: so.puDate,
            dueDate: so.dueDate,
            deliverDate: so.deliverDate,
            dropRestriction: so.dropRestriction,
            pickInDate: so.pickInDate,
            pickOutDate: so.pickOutDate,
            dropInDate: so.dropInDate,
            dropOutDate: so.dropOutDate,
            stepCount: so.stepCount,
            palletCount: so.palletCount,
            cases: so.cases,
            space: so.space,
            weight: so.weight,
            poNumber: so.poNumber,
            callCheckOutAttachment: so.callCheckOutAttachment,
            outPodRequestStatus: so.outPodRequestStatus,
            outNotes: so.outNotes,
            inPaymentMethod: so.inPaymentMethod,
            outDelayedReason: so.outDelayedReason,
            isReadyToBill: so.isReadyToBill,
            outOrderStatusByCall: so.outOrderStatusByCall,
            outPaymentMethod: so.outPaymentMethod,
            readyDate: so.readyDate,
            subDropLocation: so.subDropLocation,
            soNumber: so.soNumber,
            loadNum: so.loadNum,
        }
    }
};

const createWebOrdersArray = state => (orders, subOrders, headers) => {
    //create sub orders
    const subOrdersData = [];
    if (subOrders?.length) {
        subOrders.forEach(so => {
            subOrdersData.push(createSubOrder(
                so, 
                orders.find(o => o.id === so.orderId),
                subOrders.find(fso => fso.orderId === so.orderId && fso.parentId === so.id)
            ));
        });
    }

    const mainOrders = [];
    const subOrdersList = [];
    orders.forEach(item => mainOrders.push(createWebOrder(item, state.headers)));
    if (subOrders?.length) {
        subOrdersData.forEach(item => subOrdersList.push(createWebOrder(item, state.headers)));
    };

    const assignedOrders = [];
    mainOrders.forEach(mainOrder => {
        assignedOrders.push(mainOrder);
        const currentSubOrders = subOrdersList.filter(sub => sub.rowState.orderData.id === mainOrder.rowState.orderData.id);
        currentSubOrders.forEach(sub => assignedOrders.push(sub));

    })
    return assignedOrders;
};

export const updateOrdersState = (state, orders, subOrders, endpoint, hasError = false, message = '', filterType = 'all', sortType = {type: "", ind: "", sort: null}) => ({
    ...state, ...{
        orders,
        subOrders,
        loading: false,
        changeRequestListLoading: endpoint === 'orderschangerequestslist' ? false : true,
        openOrdersLoading: endpoint === 'availableorderlist/' ? false : true,
        hasError,
        message,
        filterType,
        sortType,
        availableOrders:
            extractWebLoadsToList(state) (
                createWebOrdersArray(state) (
                    orders,
                    subOrders,
                    state.headers,
                    state.availableOrders), state.headers),
    }
});

export const loadContent = (state, response, hasError = false, message = '') => {
    const data = response?.responce?.data || [];
    if (!response.filter) {
        return updateOrdersState(state, [...data.data], [], response.endpoint, hasError, message);
    } else {
        return updateOrdersState(state, data.availableOrders.data, data.subOrders.data, response.endpoint, hasError, message);
    }
};

export const createWebOrder = (oData, headers, isHidden) => {
    const orderData = { ...oData };

    const orderWebData = {
        row: [],
        rowState: {
            type: 'order',
            id: orderData.subOrderId ? `order_${orderData.subOrderId}` : `order_${orderData.id}`,
            orderObjectId: orderData.id,
            orderData: orderData,
            orderId: orderData.subOrderId ? `order_${orderData.orderId}` : `order_${orderData.id}`,
            isSub: !!orderData.subOrderId,
            subOrderId: orderData.subOrderId,
            visible: orderData.subOrderId ? isHidden : !isHidden,
            reacted: false,
            height: itemDefaultSize,
        }
    };
    const pathname = window.location.pathname;

    for (let hederIndex = 0; hederIndex < headers.length; hederIndex++) {
        const headerItem = headers[hederIndex];
        let value = null;
        let value2 = null;
        let fieldType = 'text';
        let editable = false;
        let cellStyle = {};
        let tooltip = null;
        if (!headerItem.leftAction) {
            switch (headerItem.key) {
                case 'id':
                    value = orderData.id;
                    break;
                case 'temperature':
                    value = orderData.temperature === 1 ? 'Frozen' : 
                            orderData.temperature === 2 ? 'Chilled' : 
                            orderData.temperature === 3 ? 'Dry' :
                            orderData.temperature === 4 ? 'C|F' : '';
                    fieldType = 'text';
                    break;
                case 'customerName':
                    value = orderData.customerName;
                    break;
                case 'orderGroupIndex':
                    value = orderData.batch === 3 ? '' : orderData.orderGroupIndex;
                    break;
                case 'price':
                    value =  orderData.price ? `$${orderData.price}` : '';
                    break;
                case 'batchType':
                    value = orderData.batchType;
                    break;
                case 'pickLocationName':
                    value = orderData.pickLocationName;
                    break;
                case 'drop2WH':
                    value = orderData.subDropLocation;
                    break;
                case 'referenceNumber':
                    value = orderData.referenceNumber;
                    break;
                case 'routeId':
                    value = orderData.routeId === 1 ? "WHS" : orderData.routeId === 2 ? "DIR" : "Both";
                    break;
                case 'deliveredDate':
                    value = pathname === "/ar/openorders" || pathname === "/ar/changerequestsar" ? orderData?.deliverDate : orderData?.deliveredDate;
                    fieldType = pathname === "/ar/openorders" || pathname === "/ar/changerequestsar" ? 'date' : 'dateTime';
                    break;
                case 'pickInDate':
                    value =  toDateTime(orderData.outboundPickIn);
                    fieldType = 'dateTime';
                    break;
                case 'puDate':
                    value = toDateTime(orderData.puDate);
                    fieldType = 'date';
                    break;
                case 'dellAppt':
                    value =  toDateTime(orderData.outboundDropAppointment);
                    fieldType = 'dateTime';
                    break;
                case 'pickOutDate':
                    value = toDateTime(orderData.outboundPickOut);
                    fieldType = 'dateTime';
                    break;
                case 'wk':
                    value = orderData.puDate && new Date(orderData.puDate).getWeek();
                    fieldType = 'text';
                    break;
                case 'space':
                    value = orderData.space;
                    fieldType = 'text';
                    break;
                case 'dueDate':
                    value = toDateTime(orderData.dueDate);
                    fieldType = 'date';
                    editable = false;
                    break;
                case 'customerCodeOrName':
                    value = orderData.customerCodeOrName;
                    fieldType = 'text';
                    break;
                case 'dropLocation':
                    value = orderData.dropLocation;
                    fieldType = 'text';
                    break;
                case 'pickLocation':
                    value = orderData.pickLocation;
                    fieldType = 'text';
                    break;
                case 'poNumber':
                    value = orderData.poNumber;
                    fieldType = 'text';
                    break;
                case 'soNumber':
                    value = orderData.soNumber;
                    fieldType = 'textInput';
                    break;
                case 'palletCount':
                    value = orderData.palletCount;
                    fieldType = 'text';
                    break;
                case 'cases':
                    value = orderData.cases;
                    fieldType = 'text';
                    break;
                case 'weight':
                    value = orderData.weight;
                    fieldType = 'text';
                    break;
                case 'lumper':
                    value =  orderData.outboundLumper ? `$${orderData.outboundLumper}` : '';
                    fieldType = 'currency';
                    break;
                case 'podRequestStatus':
                    let tempValue =  orderData.outPodRequestStatus ;
                    if(pathname === "/ar/deliverednopod"){
                        value = tempValue
                        editable = true;
                        fieldType = 'select';
                    } else {
                        const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested' };
                        value = selectOptionsPOD[tempValue]
                        editable = false;
                        fieldType = 'text';
                    }
                    tooltip = orderData.outPodRequestAuthorFullName?.trim() ?
                        `Last Changed: ${orderData.outPodRequestAuthorFullName}` :
                        `No changes`;
                    break;
                case 'podDoc':
                    value = orderData.callCheckOutAttachment?.fileName;
                    if(pathname === "/ar/deliverednopod"){
                        editable = true;
                        fieldType = 'upload';
                    } else {
                        fieldType = 'download';
                        editable = false;
                    }
                    tooltip = orderData.callCheckOutAttachment?.fullName?.trim() ?
                        `Last Changed: ${orderData?.callCheckOutAttachment?.fullName}` :
                        `No changes`;
                        break;
                case 'orderNotesByCall':
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    value2 = orderData.outPodNotesHistory;
                    // value = loadData.loadType === 1 ? orderData.inNotes : loadData.loadType === 3 ? orderData.outNotes : '';
                    // fieldType = 'select';
                    // editable = true;
                    // break;
                    let tempValueOrderNotes = orderData.outNotes;
                    if(pathname === "/ar/deliverednopod"){
                        value = tempValueOrderNotes
                        editable = true;
                        fieldType = 'select_and_popover';
                    } else {
                        const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject', 'Detoured': 'Detoured' };
                        value = selectOptionsPODStatus[tempValueOrderNotes]
                        editable = false;
                        fieldType = 'popover';
                    }
                    break;
                case 'outPodRequestStatus':
                    value = orderData.outPodRequestStatus;
                    value = orderData.outPodRequestStatus === 1 ? 'Received' : orderData.outPodRequestStatus === 2 ? 'Requested' : '-';
                    break;
                case 'freight':
                    value = orderData.freight ? `$ ${orderData.freight}` : '';
                    break;                
                case 'orderStatus':
                    value = orderData.orderStatus;
                    break;
                case 'actions':
                    value = 'text';
                    fieldType = 'actions';
                    break;
                case 'readyToBill':
                    value = "SEND TO BILL";
                    fieldType = 'button';
                    break
                case 'status':
                    value = "BILLED";
                    fieldType = 'buttonText';
                    break
                case 'billedBy':
                    value = orderData.billedByFullName;
                    fieldType = 'text';
                    break
                case 'billedDate':
                    value = orderData.billedDate;
                    fieldType = 'dateTime';
                    break
                case 'changeHistory':
                    value = "Change History";
                    fieldType = 'button';
                    break
                case 'priceTemplateType':
                    value = orderData.priceTemplateType === 1 ? 'Spot Price' : orderData.priceTemplateType > 1 ? 'Price Template' : 'Spot Price';
                    fieldType = 'text';
                    break
                case 'recalculation':
                    value = "Recalculation";
                    fieldType = 'button';
                    break
                case 'confirm':
                    value = "Confirm";
                    fieldType = 'button';
                    break
                default:
                    break;
            }
        }
        else {
            value = '';
            fieldType = 'text';
        }

        orderWebData.row.push({
            key: headerItem.key,
            value,
            value2,
            fieldType,
            displayState: 1,
            editable,
            cellStyle,
            tooltip
        });
    }

    return orderWebData;
};

export const setHeaderWidth = (state, ind, width) => {
    const header = state.headers[ind];
    header.width = width;
    return {
        ...state, ...{
            headers: [
                ...state.headers.slice(0, ind),
                ...[header],
                ...state.headers.slice(ind + 1)
            ],
            availableOrders: state.availableOrders,
        }
    };
};

export const getChangeRequestData = (state, data) => {
    return {
        ...state, ...{
            changeRequestData: data.data,
            loading: false,
        }
    };
};

export const setOrderId = (state, orderId) => {
    return {
        ...state, ...{
            orderId: orderId,
        }
    };
};

export const setChecked = (state, orderId) => {
    const availableOrders = state.availableOrders;
    if (orderId?.length) {
        availableOrders.map(order => order.rowState.checked = true);
        return {
            ...state, ...{
                availableOrders: availableOrders
            }
        }
    } else if (orderId?.length === 0) {
        availableOrders.map(order => order.rowState.checked = false);
        return {
            ...state, ...{
                availableOrders: availableOrders
            }
        }
    } else {
        const index = availableOrders.findIndex(i => i.rowState.orderObjectId === orderId);
        const item = availableOrders[index];
        availableOrders.map(order => order.rowState.checked = false);
        item.rowState.checked = true;
        return {
            ...state, ...{
                availableOrders:
                [
                    ...state.availableOrders.slice(0, index),
                    ...[item],
                    ...state.availableOrders.slice(index + 1)
                ],
            }
        }
    };
};

export const filterDeliveredTrucks = (state, type) => {
    return {
        ...state,
        ...{
            filterType: type,
        },
    }
};

export const sortBy = (state, sortValue) => ({
    ...state,
    ...{
        sortType: sortValue,
    },
});

export const reactedAssigned = (state, isSub, id) => {
    const index = state.availableOrders.findIndex(i => i.rowState.id === id && !isSub);
    const item = state.availableOrders[index];

    if (item) {
        item.rowState.reacted = !item.rowState.reacted;
        if (!isSub) {
            const subOrders = state.availableOrders.filter(ord => ord.rowState.isSub && ord.rowState.orderId === id);
            subOrders.forEach(o => o.rowState.visible = !o.rowState.visible);

            return {
                ...state, 
            }
        }
    }
    return state;
};

export const updateLoadOrderGroupData = (state, loadData, changedOrder, header, newValue, newOrders) => {
    let stopsOrders;
    let orderLoad;

    const orderStops = state.availableOrders.filter(order => order.rowState?.orderData?.id === changedOrder.id);
    const changedOrderLoadType = loadData.loadType;
    const orderStopId = orderStops.find(stop => stop.rowState.loadData.loadType === changedOrderLoadType).rowState.stopId;
    
    const stopOrdersState = state.availableOrders.filter(order => order.rowState.stopId === orderStopId);
    const stopOrdersIds = stopOrdersState.map(order => order.rowState.orderData.id);
    stopsOrders = newOrders.filter(order => stopOrdersIds.includes(order.id));
    orderLoad = state.availableOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadData.id);

    if (orderLoad) {
        stopsOrders.forEach(order => {

            if (header.key === 'orderStatusByCall') {
                if (orderLoad.rowState.loadData.loadType === 3) {
                    order['outOrderStatusByCall'] = newValue;
                    if (newValue === 0 || newValue === 1) {
                        order['outDelayedReason'] = null;
                    }
                } else if (orderLoad.rowState.loadData.loadType === 1) {
                    if (newValue === 0 || newValue === 1) {
                        order['inDelayedReason'] = null;
                    }
                    order['inOrderStatusByCall'] = newValue;
                }
            } else
            if (header.key === 'delayedReason') {
                if (orderLoad.rowState.loadData.loadType === 3) {
                    order['outDelayedReason'] = newValue;
                } else if (orderLoad.rowState.loadData.loadType === 1) {
                    order['inDelayedReason'] = newValue;
                }
            }
            order[header.key] = newValue;
        });
    }
};

export const setData = (state, newValue, rowState, header) => {
    if (rowState.orderData || rowState.firstOrderData) {
        const newOrders = [...state.orders];
        const changedOrder = rowState.firstOrderData ? 
                newOrders.find(o => o.id === rowState.firstOrderData.id) :
                newOrders.find(o => o.id === rowState.orderData.id);
        if (changedOrder) {
            if (header.key === 'orderStatusByCall') {
                    changedOrder['outOrderStatusByCall'] = newValue;
                    if (newValue !== 2) {
                        changedOrder['outDelayedReason'] = null;
                    }
            } else if (header.key === 'delayedReason') {
                    changedOrder['outDelayedReason'] = newValue;
            } else if (header.key === 'orderNotesByCall') {
                    changedOrder['outNotes'] = newValue;
            } else if (header.key === 'podRequestStatus') {
                    changedOrder['outPodRequestStatus'] = newValue;
            } else if (header.key === 'podDoc') {
                    changedOrder['callCheckOutAttachment'] = newValue;
            }
            changedOrder[header.key] = newValue;
        }
        // updateLoadOrderGroupData(state, rowState?.loadData, changedOrder, header, newValue, newOrders);
        return updateOrdersState(state, newOrders,state.hasError, state.message, state.filterType, state.sortType);
    }
    else {
        return state;
    }
};

export const updatePrice = (state, responce) => {
    const data = responce.responce.data || [];
    const id = responce.id;
    const orders = state.orders;
    const selectedOrder = orders.filter(order => order.id === id)[0];
    const selectedOrderIndex = orders.findIndex(order => order.id === id);
    selectedOrder.price = data.price;
    selectedOrder.priceTemplateType = data.priceTemplateType;

    const updatedOrders = [
        ...state.orders.slice(0, selectedOrderIndex),
        ...[selectedOrder],
        ...state.orders.slice(selectedOrderIndex + 1)
    ];
    return updateOrdersState(state, updatedOrders, state.hasError, state.message, state.filterType, state.sortType);
};

export const reCalculatePrice = (state, responce) => {
    const price = responce.price;
    const id = responce.id;
    const priceTemplateType = responce.priceTemplateTypeId;
    const orders = state.orders;
    const selectedOrder = orders.filter(order => order.id === id)[0];
    const selectedOrderIndex = orders.findIndex(order => order.id === id);
    selectedOrder.price = price;
    selectedOrder.priceTemplateType = priceTemplateType;
    const updatedOrders = [
        ...state.orders.slice(0, selectedOrderIndex),
        ...[selectedOrder],
        ...state.orders.slice(selectedOrderIndex + 1)
    ];
    return updateOrdersState(state, updatedOrders, responce.endpoint, state.hasError, state.message, state.filterType, state.sortType);
};

export const setPriceAsCalculated = (state, responce) => {
    const list = state.priceIsCalculated;
    list.push(responce);
    return {
        ...state, 
        ...{
            priceIsCalculated: list,
        }
    }
};

export const setDisplayState = (state, rowState, header, enable) => ({
    ...state, ...{
        lastSelected: {
            id: rowState.id,
            headerKey: header.key,
            enable: enable
        }
    }
});

export const setError = (state, err) => ({
    ...state,
    ...{
        hasError: true,
        message: 'Something went wrong, please try again',
    },
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: ''
    }
});

export const setInLoading = (state, endpoint) => ({
    ...state, ...{
        loading: true,
        changeRequestListLoading: endpoint === 'orderschangerequestslist' ? true : false,
        openOrdersLoading: endpoint === 'availableorderlist/' ? true : false,
    }
});
