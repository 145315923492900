import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Defines } from '../../../../project/Defines';
import { DatePicker, InputNumber, Select, Tooltip, Popover, message } from 'antd';
import { setItemData, setItemDisplayState } from '../../../../store/actions/callCheck/callCheck';
import { enabled } from 'store/dist/store.legacy';
import { GenerateMessages } from '../../../helper/element';
import Uploader from './Uploader';
import CommentsModal from './CommentsModal';
import NotesModal from './NotesModal';
import DeliveredModal from './DeliveredModal';
import './index.css';
import HistoryModal from '../../custom/spreadsheet/HistoryModal';

const Cell = (
    {
        state,
        cellData,
        itemSize,
        startOnScroll,
        rowState,
        setItemData,
        setItemDisplayState,
        resource,
        loadsAssignedOrders,
        setUploadModalIsOpen,
        setUploadRowState,
        hasSubOrder,
        searched_PONumber,
        searched_SONumber,
        searched_INLoadNumber,
        searched_OUTLoadNumber
    }) => {
    const loadArea = state[resource];
    const redonly = 1;
    const editable = 2;
    const header = loadArea.headers.find(h => h.key === cellData.key);
    // const headerIndex = loadArea.headers.findIndex(h => h.key === cellData.key);
    const selectNameOptionsStatus = [{ name: '-', id: 0 }, { name: 'On time', id: 1 }, { name: 'Delayed', id: 2 }];
    const selectNameOptionsReason = [{ name: '-', id: 0 }, { name: 'Mechanical', id: 1 }, { name: 'Delivery conflict', id: 2 }, { name: 'Internal reason', id: 3 }, { name: 'Weather', id: 4 }, { name: 'Other', id: 5 } ];
    const selectNameOptionsPODStatus = [ { name: 'Delivered', id: 'Delivered' }, { name: 'Shortage', id: 'Shortage' }, { name: 'Overage', id: 'Overage' }, { name: 'Reject', id: 'Reject' }, { name: 'Detoured', id: 'Detoured' }];
    const selectOptionsStatus = { 0: '-', 1: 'On time', 2: 'Delayed' };
    const selectOptionsReason = { null: '-', 0: '-', 1: 'Mechanical', 2: 'Delivery conflict', 3: 'Internal reason', 4: 'Weather', 5: 'Other' };
    const selectOptionsPalletTypes = { null: '', 1: 'Straight', 2: 'Chimney', 3: 'Other', 4: 'Sideways' };
    const selectOptionsPODStatus = { 'Delivered': 'Delivered', 'Shortage': 'Shortage', 'Overage': 'Overage', 'Reject': 'Reject', 'Detoured': 'Detoured'};
    const selectNameOptionsPOD = [{ name: '-', id: 0 }, { name: 'Received', id: 1 }, { name: 'Requested', id: 2 }, { name: 'Don’t Split', id: 3}];
    const selectOptionsPOD = { null: '-', 0: '-', 1: 'Received', 2: 'Requested', 3: 'Don’t Split' };
    
    // const offsetWidth = loadArea.headers.reduce((r, h, index) => {
    //     if (h.visible && index < (headerIndex - 3)) {
    //         r += h.width;
    //     }
    //     return r;
    // }, 0);
    const value2= cellData.value2;
    const width = header.width;
    const lastSelectId = loadArea.lastSelected.id;
    const lastSelectHeaderKey = loadArea.lastSelected.headerKey;
    const lastSelectenable = loadArea.lastSelected.enable;
    const rowStateId = rowState.id;
    const headerKey = header.key;
    const [displayState, setDisplayState] = useState(redonly);
    const [tempData, setTempData] = useState(null);
    const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);

    const ordersAssignedToCurrentLoad = loadsAssignedOrders.filter(order => order.rowState.loadData.id === rowState.loadId && order.rowState.type === 'order');
    const poNumbers = ordersAssignedToCurrentLoad.map(order => order.rowState.orderData.poNumber).join(', ');
    const mailBodyText = `Please send POD for the bellow PO#s: ${poNumbers}`;
    const carrierEmail = rowState.loadData.carrierEmail;

    useEffect(() => {
        if (lastSelectId === rowStateId && lastSelectHeaderKey === headerKey && lastSelectenable) {
            setDisplayState(enabled);
        }
    }, [lastSelectId, lastSelectHeaderKey, lastSelectenable, rowStateId, headerKey]);

    useEffect(() => {
        if ((headerKey === 'orderStatusByCall' ||
            headerKey === 'delayedReason' ||
            (headerKey === 'loadCallCheckNote' && headerKey === 'stop') ||
            headerKey === 'podDoc' ||
            headerKey === 'orderNotesByCall' ||
            headerKey === 'podRequestStatus') &&
            cellData.editable) {
            setDisplayState(editable);
        }
    }, [cellData.editable, headerKey]);

    const data = cellData.value;
    const podNotesHistory = cellData?.value2;
    const cellStyle = cellData.cellStyle;
    const tooltip = cellData.tooltip;
    const color = rowState.type === 'load' ? 'white' : '#101C1C';

    useEffect(() => {
        setTempData(data);
    }, [data]);

    useEffect(() => {
        if (inputReference && inputReference.current && 
                (headerKey !== 'orderStatusByCall' &&
                headerKey !== 'delayedReason' &&
                headerKey !== 'loadCallCheckNote' &&
                headerKey !== 'podDoc' &&
                headerKey !== 'orderNotesByCall' &&
                headerKey !== 'podRequestStatus')
            ){
                inputReference.current.focus();
            }
    }, [displayState, headerKey]);

    const inputReference = useRef(null);

    const dataCompare = (newData, data) => {
        switch (cellData.fieldType) {
            case 'date':
            case 'dateTime':
                if (newData instanceof Date && data instanceof Date) {
                    return newData.getTime() === data.getTime();
                }
                return newData === data;
            default:
                return newData === data;
        }
    }

    const setData = value => {
        onSetBlur();
        let newValue = value;
        if (value !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    newValue = new Date(value);
                    break;
                case 'dateTime':
                    newValue = new Date(value);
                    break;
                case 'text':
                    newValue = value;
                    break;
                default:
                    newValue = value;
                    break;
            }
        }
        const isApptDate = cellData.key === 'pickOutDate' || cellData.key === 'pickInDate' || 
                            cellData.key === 'dropInDate' || cellData.key === 'dropOutDate';
        if (!rowState.loadData?.carrierId && rowState.orderData?.subOrderId && isApptDate) {
            message.error('Unable to add the date. Please add a carrier to the load first');
            setTimeout(() => {
                setItemDisplayState(resource, rowState, header, true);
            }, 100);
            } else {
                if (!dataCompare(newValue, tempData)) {
                    setTempData(newValue);  
                    setTimeout(() => {
                        setItemData(resource, newValue, rowState, header);
                    }, 100);
                }
        }
    };

    const changeImage =  (data) => {
        setTimeout(() => {
            setUploadRowState(rowState);
        }, 100);
    };

    const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
    const [notesModalIsOpen, setNotesModalIsOpen] = useState(false);

    const handleEdit = () => {
        setCommentsModalIsOpen(true);
    };

    // create condition for disable/enable for Delivered button
    const onlyOrders = loadsAssignedOrders.filter(order => order.rowState.orderData);
    const ordersForThisLoad = onlyOrders.filter(order => order.rowState.loadData.id === rowState.loadId);

    const ordersIsInbound = rowState.loadData?.loadType === 1;
    const ordersIsOutbound = rowState.loadData?.loadType === 3;

    const ordersHaveFullDataInbound = ordersForThisLoad.filter(order => 
        order.rowState.orderData.inboundPickIn &&
        order.rowState.orderData.inboundPickOut &&
        order.rowState.orderData.inboundDropIn &&
        order.rowState.orderData.inboundDropOut
    );

    const ordersHaveFullDataOutBound = ordersForThisLoad.filter(order => 
        order.rowState.orderData.outboundPickIn &&
        order.rowState.orderData.outboundPickOut &&
        order.rowState.orderData.outboundDropIn &&
        order.rowState.orderData.outboundDropOut
    );

    const canDelivered = (ordersIsInbound && (ordersForThisLoad.length && ordersForThisLoad.length === ordersHaveFullDataInbound.length)) ||
                        (ordersIsOutbound && (ordersForThisLoad.length && ordersForThisLoad.length === ordersHaveFullDataOutBound.length));

    const [deliveredModalIsOpen, setDeliveredModalIsOpen] = useState(false);
    const handleDelivered = () => {
        setDeliveredModalIsOpen(true);
    };

    const handleClickOnNotes = () => {
        setNotesModalIsOpen(true);
    };

    const getData = () => {
        if (tempData !== '') {
            switch (cellData.fieldType) {
                case 'date':
                    return tempData ? moment(tempData).format(Defines.Format.PrintDate) : '';
                case 'dateTime':
                    if (header.key === 'inboundDeliveryEstimate' && rowState.loadData.loadType === 3) {
                        return;
                    }
                    return <div className='date-time__container'>
                        <div className='date__part'>{tempData ? moment(tempData).format(Defines.Format.Date) : ''}</div>
                        <div className='time__part'>{tempData ? moment(tempData).format(Defines.Format.Time) : ''}</div>
                    </div>;
                case 'link':
                    let bgColorLOADNumber = '';
                    if (header.key === 'loadNumber') {
                        if (searched_OUTLoadNumber.length) {
                            bgColorLOADNumber = 'lightGray'
                        } else if (searched_INLoadNumber.length) {
                            bgColorLOADNumber = 'lightGray'
                        }
                        return <Link style={{ color, textDecoration: 'underline', background: bgColorLOADNumber }} to={rowState.loadData.loadType === 3  ? '/loadplanning/outboundloadbuld' : '/loadplanning/inboundloadbuld'}>{tempData}</Link>
                    } else {
                        return <Link style={{ color, textDecoration: 'underline' }} to={rowState.loadData.loadType === 3  ? '/loadplanning/outboundloadbuld' : '/loadplanning/inboundloadbuld'}>{tempData}</Link>
                    }
                case 'text':
                    let colorClass = '';
                    if (((header.key === 'palletCount' || header.key === 'space') && tempData > 30) ||
                        (header.key === 'weight' && tempData > 40000)) {
                        colorClass = 'dark-purple'
                    }
                    if (header.key === 'loadPositionType') {
                        return selectOptionsPalletTypes[tempData];
                    } else if (header.key === 'carrierName' && rowState.type === 'load') {
                        return (
                            <a  className='call-checking__carrierName'
                                href={`mailto:${carrierEmail}?subject=POD request&body=${mailBodyText}`}>
                                {tempData}
                            </a>)
                    } else if (header.key === 'loadCallCheckNote' && rowState.type === 'load') {
                        return (
                            <div 
                                style={{ cursor: 'pointer' }}
                                onClick={handleClickOnNotes}
                                >
                                {tempData}
                            </div>)
                    } else if (header.key === 'lumper_efs' && rowState.type === 'stop') {
                        const efsData = value2 === 2 ? 'EFS: NO' : value2 === 1 ? 'EFS: YES' : 'EFS: -';
                        const lumperData = tempData === 2 ? 'YES' : tempData === 1 ? 'NO' : '-';
                        return <Tooltip title={efsData}>
                            {lumperData}
                        </Tooltip>
                    } else if ((header.key === 'palletCount' || 
                        header.key === 'space' || 
                        header.key === 'cases' || 
                        header.key === 'weight') && !!Number(value2)) {
                        return cellData.isBold ? <b>{tempData}</b> :
                            <span className={colorClass}>
                                {`${Number(tempData) - Number(value2)}/${tempData}`}
                            </span>;
                    } else {
                        return tempData;
                    }
                case 'textInput':
                    let bgColorSO = '';
                    if (header.key === 'soNumber') {
                        if (searched_SONumber.length) {
                            bgColorSO = 'lightGray'
                        }
                        return <div style={{ background: bgColorSO }}>{tempData}</div>
                    } else {
                        return tempData;
                    }
                case 'textInputPO':
                    let asterisks = '';
                    let bgColorPO = '';
                    for(let i = 0; i < value2; i++) {
                        asterisks += '*';
                    }
                    if (searched_PONumber.length) {
                        bgColorPO = 'lightGray'
                    }
                    return <div style={{ display: 'flex'}}>
                        <input
                            defaultValue={tempData ? tempData : ''}
                            className={`disabled__input ${value2 ? 'po__data' : ''} `}
                            style={{ background: bgColorPO }}
                            disabled
                        />
                        {value2 &&
                            <span
                                className='disabled__input asterisk'
                                style={{ background: bgColorPO }}
                                onClick={() => setHistoryModalOpen(true)}>
                            {value2 ? asterisks : ''}
                            </span>}
                    </div>
                case 'actions':
                    return (
                        <div className='edit_icons-callcheck'>
                        <div onClick={handleEdit}>
                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1965 6.02L12.1165 6.94L3.05647 16H2.13647V15.08L11.1965 6.02ZM14.7965 0C14.5465 0 14.2865 0.1 14.0965 0.29L12.2665 2.12L16.0165 5.87L17.8465 4.04C18.2365 3.65 18.2365 3.02 17.8465 2.63L15.5065 0.29C15.3065 0.09 15.0565 0 14.7965 0ZM11.1965 3.19L0.136475 14.25V18H3.88647L14.9465 6.94L11.1965 3.19Z" fill="#3C8680"/>
                            </svg>
                        </div>
                        </div>
                    );
                case 'button':
                    return (
                        <div className='delivered_btn-callcheck__container'>
                            <button className={`delivered_btn-callcheck ${!canDelivered && 'disabled__btn'} `} disabled={!canDelivered} onClick={handleDelivered}>Delivered</button>
                        </div>
                    );
                case 'integer':
                    if ((header.key === 'palletCount' ||
                        header.key === 'space' ||
                        header.key === 'cases' ||
                        header.key === 'weight') && value2) {
                        return cellData.isBold ? <b>{tempData}</b> : value2 && tempData ? `${tempData - value2}/${tempData}` : tempData ;
                    }
                    return cellData.isBold ? <b>{tempData}</b> : tempData;
                default:
                return tempData;
        }
        }
        return tempData
    };

    const handleSelectChange = (value) => {
        if (header.key === 'orderNotesByCall') {
            setData(value);
        } else {
            setData(parseInt(value));
        }
    };

    const handleValidationForPickDropInOut = value => {
        let errorText;
        const pickIn = rowState.loadData?.loadType === 1 ? 'inboundPickIn' : 'outboundPickIn'; 
        const pickOut = rowState.loadData?.loadType === 1 ? 'inboundPickOut' : 'outboundPickOut'; 
        const dropIn = rowState.loadData?.loadType === 1 ? 'inboundDropIn' : 'outboundDropIn'; 
        const dropOut = rowState.loadData?.loadType === 1 ? 'inboundDropOut' : 'outboundDropOut'; 

        if (header?.key === 'pickInDate') {
            if (rowState.orderData[pickOut] && !value) {
                errorText = 'Error: Cannot remove Pick In with Pick Out set.'
            }
        } else if (header?.key === 'pickOutDate') {
            if (!rowState.orderData[pickIn] && value) {
                errorText = 'Error: Pick Out cannot be set without Pick In.'
            }
            if (rowState.orderData[dropIn] && !value) {
                errorText = 'Error: Cannot remove Pick Out with Drop In set.'
            }
        } else if (header?.key === 'dropInDate') {
            if (!rowState.orderData[pickOut] && value) {
                errorText = 'Error: Del In cannot be set without Pick In/Out'
            }
            if (rowState.orderData[dropOut] && !value) {
                errorText = 'Error: Cannot remove Drop In with Drop Out set.'
            }
        } else if (header?.key === 'dropOutDate') {
            if (!rowState.orderData[dropIn] && value) {
                errorText = 'Error: Del Out cannot be set without Del In.'
            }
        }
        return errorText;
    }

    let splittedData = [];
    if (header.key === 'orderNotesByCall' && podNotesHistory && podNotesHistory?.[0]) {
        splittedData = podNotesHistory?.split(',');
    };

    const content = splittedData.length ? (
        <div>
            <table className='pod-status-modal__table'>
                <tr className='pod-status-modal__tr'>
                    <th className='pod-status-modal__th'>
                    Created By &nbsp; &nbsp; &nbsp; Data Time &nbsp; &nbsp; Status note</th>
                </tr>
                {splittedData?.map(data => (
                    <tr className='pod-status-modal__tr' >
                        <td className='pod-status-modal__td'>{data}</td>
                    </tr>
                ))}
            </table>
        </div>
    ) : <div>You don't have any notes</div>;

    const getInput = () => {
        switch (cellData.fieldType) {
            case 'dateTime':
                return <DatePicker
                    open={displayState === editable}
                    ref={inputReference}
                    onBlur={(value) => {
                        setTimeout(() => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }, 0);
                    }}
                    onChange={(value) => {
                        let errorText = handleValidationForPickDropInOut(value);
                        if (errorText) {
                            onSetBlur();
                            setTimeout(() => {
                                if (inputReference.current) {
                                    setDisplayState(redonly);
                                }
                                message.error(errorText)
                            }, 0);
                        } else {
                            setData(value === null ? '' : value._d)
                        }
                    }}
                    suffixIcon={null}
                    showTime
                    minuteStep={15}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDateTime) : ''}
                    format={Defines.Format.PrintDateTime}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    showToday={false} />;
            case 'date':
                return <DatePicker
                    open={displayState === editable}
                    ref={inputReference}
                    onBlur={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value.currentTarget.value)
                        onSetBlur();
                    }}
                    onChange={(value) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(value === null ? '' : value._d);
                    }}
                    suffixIcon={null}
                    className='spreadsheet-picker'
                    value={tempData ? moment(tempData, Defines.Format.PrintDate) : ''}
                    format={Defines.Format.PrintDate}
                    onKeyDown={e => {
                        if (e.key === 'Escape') {
                            onSetBlur();
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                        }
                    }}
                    showToday={false}
                />;
            case 'integer':
                return <InputNumber type="number" min={0}
                    ref={inputReference}
                    value={tempData ? tempData : ''}
                    onBlur={(e) => {
                        if (inputReference.current) {
                            setDisplayState(redonly);
                        }
                        setData(e.target.value)
                    }}
                    className='spreadsheet-number-input'
                />;
            case 'textInput':
                return <input
                    defaultValue={tempData ? tempData : ''}
                    ref={inputReference}
                    onBlur={(value) => {
                        setData(value.currentTarget.value)
                    }}
                    style={{ cursor: 'pointer', textAlign: 'center', width: 'inherit' }}
                    className={header.key === 'loadCallCheckNote' ? 'green__BG' : ''}
                />;
            case 'textInputPO':
                let asterisks = '';
                for(let i = 0; i < value2; i++) {
                    asterisks += '*';
                }
                return <div style={{ display: 'flex'}}>
                    <input
                        ref={inputReference}
                        defaultValue={tempData ? tempData : ''}
                        className={`disabled__input ${value2 ? 'po__data' : ''} `}
                        onBlur={(value) => {
                            if (inputReference.current) {
                                setDisplayState(redonly);
                            }
                            setData(value.currentTarget.value)
                        }}
                    />
                    {value2 &&
                        <span
                            className='disabled__input asterisk'
                            ref={inputReference}
                            onClick={() => setHistoryModalOpen(true)}>
                        {asterisks}
                        </span>
                    }
                </div>
            case 'select':
                let selectOption;
                let selectNameOptions;
                if (header.key === 'orderStatusByCall') {
                    selectOption = selectOptionsStatus;
                    selectNameOptions = selectNameOptionsStatus;
                } else if (header.key === 'delayedReason') {
                    selectOption = selectOptionsReason;
                    selectNameOptions = selectNameOptionsReason;
                } else if (header.key === 'podRequestStatus') {
                    selectOption = selectOptionsPOD;
                    selectNameOptions = selectNameOptionsPOD;
                }
                const statusByCell = rowState.loadData.loadType === 1 ?
                        rowState?.firstOrderData?.inOrderStatusByCall :
                        rowState?.firstOrderData?.outOrderStatusByCall;
                return <Select 
                    ref={inputReference} 
                    value={header.key === 'delayedReason' && statusByCell !== 2 ? selectOption[0] : selectOption[`${tempData}`]}
                    disabled={header.key === 'delayedReason' && statusByCell !== 2}
                    className={`rowSelect ${rowState.type === 'stop' ? 'stop_cell': ''} ${rowState.type === 'order' ? 'order_cell' : ''} ${rowState?.orderData?.subOrderId ? 'sub_order_cell' : ''}`}
                    onChange={handleSelectChange}
                    defaultValue={selectOption[`${tempData}`]}>
                    {selectNameOptions.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))}
                </Select>;
            case 'select_and_popover':
                        return (
                        <Popover content={content} title="POD status list">
                            <Select 
                                ref={inputReference} 
                                value={selectOptionsPODStatus[`${tempData}`]}
                                className={`rowSelect ${rowState.type === 'stop' ? 'stop_cell': ''} ${rowState.type === 'order' ? 'order_cell' : ''} ${rowState?.orderData?.subOrderId ? 'sub_order_cell' : ''}`}
                                onChange={handleSelectChange}
                                defaultValue={selectOptionsPODStatus[`${tempData}`]}>
                                {selectNameOptionsPODStatus.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Popover>
                        );
            case 'upload':
                if ((rowState.orderData?.subOrderId && rowState.orderData?.outPodRequestStatus === 3) ||
                    (rowState.loadData?.loadType === 1 && (rowState?.orderData.inPodRequestStatus === 0 || rowState?.orderData.inPodRequestStatus === 2)) || 
                    (rowState.loadData?.loadType === 3 && (rowState?.orderData.outPodRequestStatus === 0 || rowState?.orderData.outPodRequestStatus === 2))) {
                    return <div style={{position: 'relative'}}>
                        <div className='uploader-container'>
                            <Uploader
                                rowState={rowState}
                                setData={() => {}}
                                setUploadModalIsOpen={setUploadModalIsOpen}
                                headerWidth={header.width}
                                // hasSubOrder={hasSubOrder}
                                // isSub={rowState.orderData?.subOrderId}
                                disabled
                                >
                            </Uploader>
                        </div>
                    </div>;
                } else if ((!hasSubOrder && (rowState?.orderData?.outPodRequestStatus === 3 || rowState?.orderData?.inPodRequestStatus === 3))) {
                    return <div>Upload</div>
                } else {
                    return <div style={{position: 'relative'}}>
                    <div onClick={() => changeImage(rowState)} className='uploader-container'>
                        <Uploader
                            rowState={rowState}
                            setData={setData}
                            setUploadModalIsOpen={setUploadModalIsOpen}
                            headerWidth={header.width}
                            // hasSubOrder={hasSubOrder}
                            // isSub={rowState.orderData?.subOrderId}
                            >
                        </Uploader>
                    </div>
                </div>;
                }
            default: return <></>;
        }
    };

    const getDataWithTooltip = () => tooltip ? 
    (<Tooltip title={GenerateMessages([tooltip])} placement="right">
        {displayState === redonly ? getData() : getInput()}
    </Tooltip>) : 
    displayState === redonly ? getData() : getInput();

    const dataDisplay = () =>  getDataWithTooltip();

    const cellDefaultStyle = {
        maxWidth: `${width}px`,
        width: `${width}px`,
        minWidth: `${width}px`,
        height: `${itemSize}px`,
        lineHeight: 'inherit',
    };

    const onSetFocus = () => {
        // startOnScroll && startOnScroll(offsetWidth);
        if (cellData.editable) {
            setDisplayState(editable);
        }
        // setItemDisplayState(resource, rowState, header, cellData.editable);
    };

    const onSetBlur = () => {
        setItemDisplayState(resource, rowState, header, false);
    };

    const editableFunction = (cellData) => (
        cellData.editable ? setDisplayState(editable) : null
    );

    return (
        <>
            <div
                onFocus={() => onSetFocus()}
                onClick={() => { editableFunction(cellData)}}
                className='table-body-cell'
                style={{ ...cellDefaultStyle, ...cellStyle, color: color }}
                tabIndex={displayState === redonly ? 0 : -1}
                >
                {dataDisplay()}
            </div>
            {commentsModalIsOpen && 
                <CommentsModal
                    commentsModalIsOpen={commentsModalIsOpen}
                    setCommentsModalIsOpen={setCommentsModalIsOpen}
                    rowState={rowState}
                />
            }
            {deliveredModalIsOpen && 
                <DeliveredModal
                    deliveredModalIsOpen={deliveredModalIsOpen}
                    setDeliveredModalIsOpen={setDeliveredModalIsOpen}
                    rowState={rowState}
                    loadsAssignedOrders={loadsAssignedOrders}
                    resource={resource}
                />
            }
            {notesModalIsOpen && 
                <NotesModal
                    notesModalIsOpen={notesModalIsOpen}
                    setNotesModalIsOpen={setNotesModalIsOpen}
                    rowState={rowState}
                />
            }
            {isHistoryModalOpen &&
                <HistoryModal
                    isHistoryModalOpen={isHistoryModalOpen}
                    setHistoryModalOpen={setHistoryModalOpen}
                    resource={resource}
                    rowState={rowState}
                    cellDataKey={cellData.key}
                />
            }
        </>
);
};

const mapStateToProps = state => ({
    state: state,
    loadsAssignedOrders: state.callCheck.loadsAssignedOrders,
});

const mapDispatchToProps = (dispatch) => ({
    setItemData: (resource, value, rowState, header) => dispatch(setItemData(resource, value, rowState, header)),
    setItemDisplayState: (resource, rowState, header, enable) => dispatch(setItemDisplayState(resource, rowState, header, enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cell);