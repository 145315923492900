import axios from 'axios';
import moment from 'moment'
import store from '../..';
import * as actionTypes from './accountReceivableActionTypes';

const buildUrl = (resource, filter = '', endpoint) => {
    if (endpoint) {
        return `/api/${resource}/${endpoint}`
    } else {
        if(!filter){
            return `/api/${resource}`
        } else {
            return `/api/${resource}?filterData=${filter}`
        }
    }
};

const buildUrlById = (resource, orderId) => {
    return `/api/${resource}/orderchangerequestinfo?orderId=${orderId}`
};

const buildUrlAccept = (resource, endpint) => {
    return `/api/${resource}/${endpint}`
};

const buildPriceUrl = (endpoint, id, price, priceTemplateId, priceTemplateType) => id ? `/api/${endpoint}?orderId=${id}&price=${price}&priceTemplateId=${priceTemplateId}&priceTemplateType=${priceTemplateType}` : `/api/${endpoint}`;

const axiosConfig = () => ({
    headers: {
        'Authorization': 'Bearer ' + store.getState().auth.userToken
    }
});

export const load = (resource, filter, endpoint, loadingType) => dispatch => {
    if (loadingType !== 'withoutLoading') {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ACCOUNT_RECEIVABLE_LOADING), data: endpoint });
    }
    const data  = {
        params: {
            filterData: filter,
        },
        headers: axiosConfig().headers,
    };

    axios.get(buildUrl(resource, filter, endpoint), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ACCOUNT_RECEIVABLE), data: { responce, endpoint, filter } }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCOUNT_RECEIVABLE_ERROR) });
        })
};

export const getOrderChangeRequestData = (resource, orderId) => dispatch => {
    const data  = {
        params: {
            filterData: { orderId: orderId },
        },
        headers: axiosConfig().headers,
    };
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CHANGE_REQUEST_DATA_LOADING) });
    axios.get(buildUrlById(resource, orderId), data,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CHANGE_REQUEST_DATA), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_CHANGE_REQUEST_DATA_ERROR) });
        })
};

export const reactOnAssignedItem = (resource, id, isSub) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.REACTED_ASSIGNED_AR), data: { id, isSub } });

export const setHeaderWidth = (resource, ind, width) =>
    ({ type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_ACCOUNT_RECEIVABLE), data: { ind, width } });

export const billButtonAction = (resource, filter, rowState) => dispatch =>{
    axios.put(buildUrl( `${resource}/${filter}?orderId=${rowState?.orderData?.id}`), '',
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            } else {
                dispatch(load(resource, '{"isReadyToBill": true}'))
            }
        }).catch(err => {
        dispatch(errorAction(resource, true, '{"isReadyToBill": true}'));
        console.error(err);
        console.log(err.response);
    });
}

export const setItemData = (resource, value, rowState, header) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_ACCOUNT_RECEIVABLE_DATA), data: {
            value,
            rowState,
            header
        }
    });

    let requestData = {};
    let orderDelayedReasonByCall;
    let orderStatusByCall;
    let orderNotesByCall;
    let loadCallCheckNote;
    let pickInDate;
    let pickOutDate;
    let dropInDate;
    let dropOutDate;
    let podRequestStatus;
    let callCheckAttachment;
    let browserDateTime = moment.utc(new Date()).local().utc(true).format();

    const order =  rowState.orderData;
    // if (rowState.loadData.loadType === 3) {
        orderDelayedReasonByCall = order.outDelayedReason;
        orderStatusByCall = order.outOrderStatusByCall;
        orderNotesByCall = order?.outNotes;
        podRequestStatus = order?.outPodRequestStatus
        pickInDate = order.outboundPickIn;
        pickOutDate = order.outboundPickOut;
        dropInDate = order.outboundDropIn;
        dropOutDate = order.outboundDropOut;
        callCheckAttachment = order.callCheckOutAttachment;
        // loadCallCheckNote = order.outLoadCallCheckNote;
    // }
    // else if (rowState.loadData.loadType === 1) {
    //     orderDelayedReasonByCall = order.inDelayedReason;
    //     orderStatusByCall = order.inOrderStatusByCall;
    //     orderNotesByCall = order.inNotes;
    //     podRequestStatus = order?.inPodRequestStatus;
    //     pickInDate = order.inboundPickIn;
    //     pickOutDate = order.inboundPickOut;
    //     dropInDate = order.inboundDropIn;
    //     dropOutDate = order.inboundDropOut;
    //     callCheckAttachment = order.callCheckInAttachment;
    //     loadCallCheckNote = order.inLoadCallCheckNote;
    // }
    requestData = {
        orderId: order.id,
        loadId: order.outboundLoadId,
        orderStatusByCall,
        orderDelayedReasonByCall,
        orderNotesByCall,
        podRequestStatus,
        loadCallCheckNote,
        callCheckAttachment,
        pickInDate,
        pickOutDate,
        dropInDate,
        dropOutDate,
        // isDelivered,
        browserDateTime,
    }

    if (header.key === 'orderStatusByCall') {
        requestData.orderStatusByCall = value;
        requestData.orderDelayedReasonByCall = null;
    } else if (header.key === 'delayedReason') {
        requestData.orderDelayedReasonByCall = value;
    } else if (header.key === 'orderNotesByCall') {
        requestData.orderNotesByCall = value;
    } else if (header.key === 'podRequestStatus') {
        requestData.podRequestStatus = value;
    } else if (header.key === 'podDoc') {
        requestData.callCheckAttachment = value;
    } else if (header.key === 'pickInDate') {
        requestData.pickInDate = value;
    } else if (header.key === 'pickOutDate') {
        requestData.pickOutDate = value;
    } else if (header.key === 'dropInDate') {
        requestData.dropInDate =  value
    } else if (header.key === 'dropOutDate') {
        requestData.dropOutDate = value;
    } else if (header.key === 'loadCallCheckNote') {
        requestData.loadCallCheckNote = value;
    }

    axios.put(buildUrl('AccountReceivable/loadorderitem'), requestData,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true, response.data.message));
                console.error(response.data.message);
            } else if ((response.data.callCheckInAttachment || response.data.callCheckOutAttachment) && header.key === 'podDoc') {
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.SET_ACCOUNT_RECEIVABLE_DATA), data: {
                        value: response.data.callCheckOutAttachment,
                        rowState,
                        header
                    }
                })
            } else if (header.key === 'orderNotesByCall' || header.key === 'podRequestStatus') {
                dispatch(reloadAfterUpdate(`${resource}?filterData={"isReadyToBill": false, "isBilled": false}`))
            }
        }).catch(err => {
        dispatch(errorAction(resource, true));
        console.error(err);
        console.log(err.response);
    });
};

export const setItemDisplayState = (resource, rowState, header, enable) => ({
    type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE), data: {
        rowState,
        header,
        enable
    }
});

export const filterDeliveredTrucks = (resource, key) => ({
    type: actionTypes.getActionType(resource, actionTypes.FILTER_ACCOUNT_RECEIVABLE),
    data: key
});

export const sortBy = (resource, sortValue) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SORT_ACCOUNT_RECEIVABLE), data: sortValue
    });
}

export const setChecked = (resource, orderId) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_CHECKED), data: orderId
    });
}

export const setOrderId = (resource, orderId) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_ORDER_ID), data: orderId
    });
}

export const acceptChangeRequest = (resource, endpint, orderId) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ACCOUNT_RECEIVABLE_LOADING) });
    let orderIDs = [];
    if (!orderId.length) {
        orderIDs.push(orderId);
    } else {
        orderId.map(orderId => orderIDs.push(orderId));
    };

    axios.put(buildUrlAccept(resource, endpint), orderIDs,
        axiosConfig())
        .then(response => {
            if (response.data.hasError) {
                dispatch(errorAction(resource, true));
                console.error(response.data.message);
            } else {
                dispatch(load(resource, '', 'orderschangerequestslist'))
            }
        }).catch(err => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCOUNT_RECEIVABLE_ERROR) });
    });
};


export const updatePriceByTemplate = ( resource, customerId, pickLockId, dropLockId, palletCount, weight, id ) => dispatch => {
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_PRICE_LOADING) });
    const filterData  = {
        params: {
            customerId: customerId,
            pickLockId: pickLockId,
            dropLockId: dropLockId,
            palletCount: palletCount,
            weight: weight,
        },
        headers: axiosConfig().headers,
    };
    axios.get(buildPriceUrl(`Order/pricebytemplate`), filterData,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_PRICE), data: { responce, id } }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_PRICE_ERROR) });
        })
};

export const reCalculatePrice = ( resource, id, values, endpoint ) => dispatch => {
    let { price, priceTemplateId, priceTemplateTypeId } = values;
    // priceTemplateType = priceTemplateType === 'Spot' ? 1 : priceTemplateType === 'Price Template' ? 2 : '';
    dispatch({ type: actionTypes.getActionType(resource, actionTypes.RECALCULATE_PRICE_LOADING), data: endpoint });
    const filterData = {
        orderId: id,
        price: price,
        priceTemplateId: priceTemplateId,
        priceTemplateType: priceTemplateTypeId,
    };
    axios.put(buildPriceUrl(`AccountReceivable/orderprice`, id, price, priceTemplateId, priceTemplateTypeId), filterData,
        axiosConfig())
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RECALCULATE_PRICE), data: { price, id, priceTemplateTypeId, responce, endpoint } }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.RECALCULATE_PRICE_ERROR) });
        })
};

export const setPriceAsCalculated = (resource, orderId) => dispatch => {
    dispatch({
        type: actionTypes.getActionType(resource, actionTypes.SET_PRICE_AS_CALCULATED), data: orderId
    });
}

export const resetError = resource =>
    ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

const errorAction = (resource, reload, method) => dispatch => {
    if (reload) {
        axios.get(buildUrl(resource, method),
            axiosConfig()
        )
            .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR_ACCOUNT_RECEIVABLE), data: responce }))
            .catch((err) => {
                console.error(err);
                console.log(err.response);
                dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCOUNT_RECEIVABLE_ERROR) });
            });
    }
};

const reloadAfterUpdate = (resource) => dispatch => {
    axios.get(buildUrl(resource),
        axiosConfig()
    )
        .then(responce => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_ACCOUNT_RECEIVABLE), data: responce }))
        .catch((err) => {
            console.error(err);
            console.log(err.response);
            dispatch({ type: actionTypes.getActionType(resource, actionTypes.ACCOUNT_RECEIVABLE_ERROR) });
        });
};
