import * as inboundData from './helper/inbound/data';
import * as outboundData from './helper/outbound/data';

export const InboundSubOrderFieldConvertor = {
    inboundPickIn: 'pickInDate',
    inboundPickOut: 'pickOutDate',
    inboundPickAppointment: 'pickAppointmentDate',
    inboundAppointmentStatus: 'pickAppointmentStatus',
    inboundDropIn: 'dropInDate',
    inboundDropOut: 'dropOutDate',
    inboundDropAppointment: 'dropAppointmentDate',
    inboundLumper: 'lumper',
    InPickNummber: 'InPickNummber',
    puDate: 'puDate',
    dueDate: 'dueDate',
    deliverDate: 'deliverDate'
};

export const OutboundSubOrderFieldConvertor = {
    outboundPickIn: 'pickInDate',
    outboundPickOut: 'pickOutDate',
    outboundPickAppointment: 'pickAppointmentDate',
    pickOutboundAppointmentStatus: 'pickAppointmentStatus',
    outboundDropIn: 'dropInDate',
    outboundDropOut: 'dropOutDate',
    outboundDropAppointment: 'dropAppointmentDate',
    outboundAppointmentStatus: 'dropAppointmentStatus',
    outboundLumper: 'lumper',
    InPickNummber: 'InPickNummber',
    puDate: 'puDate',
    dueDate: 'dueDate',
    deliverDate: 'deliverDate'
};

const SubOrderFieldConvertor = state => state.loadType === 'inbound' ? InboundSubOrderFieldConvertor : OutboundSubOrderFieldConvertor;

const extractWebRegionsToList = state => state.loadType === 'inbound' ? inboundData.extractWebRegionsToList
    : outboundData.extractWebRegionsToList;

const createWebRegionArray = state => state.loadType === 'inbound' ? inboundData.createWebRegionArray
    : outboundData.createWebRegionArray;

const extractWebLoadsToList = state => state.loadType === 'inbound' ? inboundData.extractWebLoadsToList
    : outboundData.extractWebLoadsToList;

const createWebLoadArray = state => state.loadType === 'inbound' ? inboundData.createWebLoadArray
    : outboundData.createWebLoadArray;

export const updateOrdersLoadState = (state, orders, loads, subOrders, hasError = false, message = '', loadsAreSorted = true, loadsSortedBy = '', openModal = false, openModalNewLoad = false, assignedOrders = []) => ({
    ...state, ...{
        orders,
        loads,
        subOrders,
        loading: false,
        hasError,
        message,
        loadsAreSorted,
        loadsSortedBy,
        assignedOrders,
        regionsAvailableOrders: extractWebRegionsToList(state)(
            createWebRegionArray(state)(
                orders.filter(o => !o[state.loadIdField]),
                state.headers,
                state.regionHeaders), state.headers),
        loadsAssignedOrders:
            extractWebLoadsToList(state)(
                createWebLoadArray(state) (
                    state.loadIdField,
                    loads,
                    orders.filter(o => o[state.loadIdField]),
                    subOrders,
                    state.headers, 
                    state.loadsAssignedOrders), state.headers, true, true),
                    openModal,
                    openModalNewLoad,
    }
});

export const loadContent = (state, response, hasError = false, message = '') => {
    const data = response.data || [];
    const sortedLoads = data.loads.data.sort((loadA, LoadB) => {
        const loadACarrierName = loadA.carrierName;
        const loadBCarrierName = LoadB.carrierName;
        if (loadACarrierName > loadBCarrierName) {
            return 1;
        }
        if (loadACarrierName < loadBCarrierName) {
            return -1;
        }
        return 0;
    })
    return updateOrdersLoadState(state, [...data.availableOrders.data, ...data.assignedOrders.data], sortedLoads, data.subOrders.data, hasError, message);
};

export const setHeaderWidth = (state, ind, width) => {
    const header = state.headers[ind];
    header.width = width;
    return {
        ...state, ...{
            headers: [
                ...state.headers.slice(0, ind),
                ...[header],
                ...state.headers.slice(ind + 1)
            ],
            regionHeaders: state.regionHeaders,
            loadsAssignedOrders: state.loadsAssignedOrders,
            regionsAvailableOrders: state.regionsAvailableOrders
        }
    };
};

export const reactedAssigned = (state, type, id) => {
    const index = state.loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
    const item = state.loadsAssignedOrders[index];
    if (item) {
        item.rowState.reacted = !item.rowState.reacted;
        if (type === 'load') {
            const loadOrders = state.loadsAssignedOrders
                .filter(i => i.rowState.loadId === id && i.rowState.type === 'order');
            loadOrders.forEach(o => o.rowState.visible = !o.rowState.visible);
            const length = loadOrders.length + 1;
            return {
                ...state, ...{
                    headers: state.headers,
                    regionHeaders: state.regionHeaders,
                    regionsAvailableOrders: state.regionsAvailableOrders,
                    loadsAssignedOrders: [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...loadOrders,
                        ...state.loadsAssignedOrders.slice(index + length)
                    ]
                }
            }
        }
        if (type === 'order') {
            return {
                ...state, ...{
                    headers: state.headers,
                    regionHeaders: state.regionHeaders,
                    regionsAvailableOrders: state.regionsAvailableOrders,
                    loadsAssignedOrders: [
                        ...state.loadsAssignedOrders.slice(0, index),
                        ...[item],
                        ...state.loadsAssignedOrders.slice(index + 1)
                    ]
                }
            }
        }
    }
    return state;
};

export const reactedAssigneds = (state, type, ids) => {
    const indexes = ids.map(id => state.loadsAssignedOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type));
    state.regionsAvailableOrders.forEach(i => i.rowState.reacted = false); // Remove if need to separate
    state.loadsAssignedOrders.forEach(i => i.rowState.reacted = false);
    const items = indexes.map(i => state.loadsAssignedOrders[i]);
    let newState;
    if (items.length) {
        if (type === 'order') {
            items.forEach(item => {
                item.rowState.reacted = true;
                const index = state.loadsAssignedOrders.findIndex(i => i.rowState.id === item.rowState.id && i.rowState.type === type);
                newState = {
                    ...state, ...{
                        headers: state.headers,
                        regionHeaders: state.regionHeaders,
                        regionsAvailableOrders: state.regionsAvailableOrders,
                        loadsAssignedOrders: [
                            ...state.loadsAssignedOrders.slice(0, index),
                            ...[item],
                            ...state.loadsAssignedOrders.slice(index + 1)
                        ]
                    }
                };
            });
        }

        return newState;
    }
    return state;
};

export const reactedAvailable = (state, type, id) => {
    const index = state.regionsAvailableOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type);
    const item = state.regionsAvailableOrders[index];
    if (item) {
        item.rowState.reacted = !item.rowState.reacted;
        if (type === 'region') {
            const regionOrders = state.regionsAvailableOrders
                .filter(i => i.rowState.regionId === id && i.rowState.type === 'order');
            regionOrders.forEach(o => o.rowState.reacted = item.rowState.reacted);
            const length = regionOrders.length + 1;

            return {
                ...state, ...{
                    headers: state.headers,
                    regionHeaders: state.regionHeaders,
                    loadsAssignedOrders: state.loadsAssignedOrders,
                    regionsAvailableOrders: [
                        ...state.regionsAvailableOrders.slice(0, index),
                        ...[item],
                        ...regionOrders,
                        ...state.regionsAvailableOrders.slice(index + length)
                    ]
                }
            }
        }
        if (type === 'order') {
            const regionIndex = state.regionsAvailableOrders.findIndex(i => i.rowState.id === item.rowState.regionId
                && i.rowState.type === 'region');
            const region = state.regionsAvailableOrders[regionIndex];
            const reactUbReactCount = state.regionsAvailableOrders
                .filter(i => i.rowState.regionId === item.rowState.regionId && i.rowState.type === 'order').reduce((r, i) => {
                    i.rowState.reacted ? r[0]++ : r[1]++;
                    return r;
                }, [0, 0]);
            if (reactUbReactCount[0] === 0) {
                region.rowState.reacted = false;
            }
            if (reactUbReactCount[1] === 0) {
                region.rowState.reacted = true;
            }

            return {
                ...state, ...{
                    headers: state.headers,
                    regionHeaders: state.regionHeaders,
                    loadsAssignedOrders: state.loadsAssignedOrders,
                    regionsAvailableOrders: [
                        ...state.regionsAvailableOrders.slice(0, regionIndex),
                        ...[region],
                        ...state.regionsAvailableOrders.slice(regionIndex + 1, index),
                        ...[item],
                        ...state.regionsAvailableOrders.slice(index + 1)
                    ]
                }
            }
        }
    }
    return state;
};

export const reactedAvailables = (state, type, ids) => {
    const indexes = ids.map(id => state.regionsAvailableOrders.findIndex(i => i.rowState.id === id && i.rowState.type === type));
    state.regionsAvailableOrders.forEach(i => i.rowState.reacted = false);
    state.loadsAssignedOrders.forEach(i => i.rowState.reacted = false); // Remove if need to separate
    const items = indexes.map(i => state.regionsAvailableOrders[i]);
    let newState;
    if (items.length) {
        if (type === 'order') {
            items.forEach(item => {
                const index = state.regionsAvailableOrders.findIndex(i => i.rowState.id === item.rowState.id && i.rowState.type === type);
                item.rowState.reacted = true;
                const regionIndex = state.regionsAvailableOrders.findIndex(i => i.rowState.id === item.rowState.regionId
                    && i.rowState.type === 'region');
                const region = state.regionsAvailableOrders[regionIndex];
                const reactUbReactCount = state.regionsAvailableOrders
                    .filter(i => i.rowState.regionId === item.rowState.regionId && i.rowState.type === 'order').reduce((r, i) => {
                        i.rowState.reacted ? r[0]++ : r[1]++;
                        return r;
                    }, [0, 0]);
                if (reactUbReactCount[0] === 0) {
                    region.rowState.reacted = false;
                }
                if (reactUbReactCount[1] === 0) {
                    region.rowState.reacted = true;
                }

                newState = {
                    ...state, ...{
                        headers: state.headers,
                        regionHeaders: state.regionHeaders,
                        loadsAssignedOrders: state.loadsAssignedOrders,
                        regionsAvailableOrders: [
                            ...state.regionsAvailableOrders.slice(0, regionIndex),
                            region,
                            ...state.regionsAvailableOrders.slice(regionIndex + 1, index),
                            item,
                            ...state.regionsAvailableOrders.slice(index + 1)
                        ]
                    }
                };
            });

            return newState;
        }
    }
    return state;
};

export const unreactOrders = state => {
    const newLoadsAssignedOrders = [...state.loadsAssignedOrders];
    newLoadsAssignedOrders.forEach(o => {
        if (o.rowState.type === 'order') {
            o.rowState.reacted = false;
        }
    });
    const newRegionsAvailableOrders = [...state.regionsAvailableOrders];
    newRegionsAvailableOrders.forEach(o => {
        if (o.rowState.type === 'order' || o.rowState.type === 'region') {
            o.rowState.reacted = false;
        }
    });
    return {
        ...state, ...{
            loadsAssignedOrders: newLoadsAssignedOrders,
            regionsAvailableOrders: newRegionsAvailableOrders
        }
    };
};

export const unassignOrders = (state, orders) =>
    unassignOrderObjects(state, orders, state.loads, state.subOrders);

export const unassignOrderObjects = (state, unAssignedOrderObjects, newLoads, newSubOrders) => {
    const newOrders = [...state.orders];
    let ordersWithSameLoadAndPosition = [];
    newOrders.forEach(o => {
        if (unAssignedOrderObjects.findIndex(unaO => unaO.id === o.id) !== -1) {
            if (state.loadType === 'inbound') {
                o.inboundPickIn = null;
                o.inboundPickOut = null;
                // o.inboundPickAppointment = null;
                // o.inboundAppointmentStatus = 1;
                o.inboundDropAppointment = null;
                o.dropInboundAppointmentStatus = 2;
                o.inboundDropIn = null;
                o.inboundDropOut = null;
                o.inboundLumper = null;
                o.efsInId = null;
                o.inEfsCreateDate = null;
                o.inEfsUpdateDate = null;
                o.inAmount = 0;
                o.inCardNumber = null;
                o.inPaymentMethod = null;
                o.inNote = null;
                // o.inPickNummber = "";
                // o.inDeliverNummber = "";
                o.inLoad = null;
            }
            else if (state.loadType === 'outbound') {
                o.outboundPickIn = null;
                o.outboundPickOut = null;
                // o.outboundPickAppointment = null;
                // o.outboundDropAppointment = null;
                // o.outboundAppointmentStatus = 1;
                o.outboundDropIn = null;
                o.outboundDropOut = null;
                o.outboundLumper = null;
                o.efsOutId = null;
                o.outEfsCreateDate = null;
                o.outEfsUpdateDate = null;
                o.outAmount = 0;
                o.outCardNumber = null;
                o.outPaymentMethod = null;
                o.outNote = null;
                // o.outPickNummber = "";
                // o.outDeliverNummber = "";
                o.outLoad = null;
            }

            // reOrdering/reArranging loadOrders (by OrderPossitionIndex)
            const OrderPossitionIndex = state.loadType === 'inbound' ? 'inOrderPossitionIndex' : 'outOrderPossitionIndex';
            const locationId = state.loadType === 'inbound' ? 'pickLocationId' : 'dropLocationId';
            const load_id = state.loadType === 'inbound' ? 'loadId' : 'outboundLoadId';
            ordersWithSameLoadAndPosition = newOrders.filter(order => order[load_id] === o[load_id] && order[locationId] === o[locationId]);
            const sortedList = ordersWithSameLoadAndPosition.sort((a,b) => a[OrderPossitionIndex] - b[OrderPossitionIndex]);
            const index = sortedList.findIndex(item => item.id === o.id);
            const newList = [
                ...sortedList.slice(0, index),
                ...sortedList.slice(index + 1)
            ];

            newList.forEach((order, ind) => {
                if (ind >= index) {
                    order[OrderPossitionIndex] = order[OrderPossitionIndex] - 1;
                }
            });

            o[state.loadIdField] = null;
        }
    });
    return updateOrdersLoadState(state, newOrders, newLoads, newSubOrders);
};

export const assignOrdersToLoad = (state, load, orders, openModal, locationObjects) =>
    assignOrdersToLoadObject(state, load.rowState.loadData.id, orders, state.loads, load.rowState.loadData.loadNum, openModal, locationObjects);

export const assignOrdersToLoadObject = (state, loadId, orders, newLoads, loadNum, openModal, locationObjects ) => {
    const pickAppointment = state.loadType === 'inbound' ? 'inboundPickAppointment': 'outboundDropAppointment';
    const pickAppointmentStatus = state.loadType === 'inbound' ? 'inboundAppointmentStatus': 'outboundAppointmentStatus';
    const location = state.loadType === 'inbound' ? 'pickLocation': 'dropLocation';
    const orderLoadId = state.loadType === 'inbound' ? 'loadId': 'outboundLoadId';

    const assignedOrderObjects = orders;
    const newOrders = [...state.orders];
    const orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadId);
    const isLoadEmpty = orderLoad?.ordersData?.length === 0;

    if (orderLoad) {
        const keysList = [orderLoad.rowState.createPickKey, orderLoad.rowState.createDropKey];
        let keyNumber = 0;
        keysList.forEach(key => {
            const orderGroups = orderLoad.rowState.groups(orderLoad.ordersData, key);
            newOrders.forEach(o => {
                if (assignedOrderObjects.findIndex(unaO => unaO.id === o.id) !== -1) {
                    o[state.loadIdField] = loadId;
                    if (state.loadType === 'inbound') {
                        o.inLoad = loadNum;
                    } else {
                        o.outLoad = loadNum;
                    }
                    if (Object.keys(orderGroups).length !== 0) {
                        const foundGroupKey = orderLoad.rowState.findOrderGroup(orderGroups, o, key);
                        if (foundGroupKey) {
                            const foundGroup = orderGroups[foundGroupKey];
                            const firstOrder = foundGroup[0];
                            const fields = keyNumber === 0 ? state.groupingPickUpdateFields : state.groupingDropUpdateFields;
                            fields.forEach(h => {
                                if (openModal) {
                                    o[h] = null;
                                } else if (!firstOrder['inboundPickAppointment']) {
                                    //
                                } else {
                                    o[h] = firstOrder[h];
                                    if (h === pickAppointment) {
                                        o[pickAppointmentStatus] = firstOrder[pickAppointmentStatus];
                                    }
                                }
                            });
                        } else {
                            const fields = keyNumber === 0 ? state.groupingPickUpdateFields : state.groupingDropUpdateFields;
                            fields.forEach(h => {
                                if (h === 'inboundAppointmentStatus' || h === 'outboundAppointmentStatus') {
                                    o[h] = 2 } // TODO
                                else {
                                    o[h] = null;
                                }
                                
                            });
                        }
                    }
                    // empty pick and drop data if load is empty
                    if (isLoadEmpty) {
                        o.inboundPickIn = null;
                        o.inboundPickOut = null;
                        o.inboundDropIn = null;
                        o.inboundDropOut = null;
                        // o.inboundPickAppointment = null;
                        // o.inboundDropAppointment = null;
                        o.outboundPickIn = null;
                        o.outboundPickOut = null;
                        o.outboundDropIn = null;
                        o.outboundDropOut = null;
                        // o.outboundPickAppointment = null;
                        // o.outboundDropAppointment = null;
                    }
                
                // reOrdering/reArranging loadOrders (by OrderPossitionIndex)
                const OrderPossitionIndex = state.loadType === 'inbound' ? 'inOrderPossitionIndex' : 'outOrderPossitionIndex';
                const locationId = state.loadType === 'inbound' ? 'pickLocationId' : 'dropLocationId';
                const load_id = state.loadType === 'inbound' ? 'loadId' : 'outboundLoadId';
                const ordersWithSameLoadAndPosition = newOrders.filter(order => order[load_id]=== o[load_id] && order[locationId] === o[locationId]);

                ordersWithSameLoadAndPosition.forEach((order, index) => {
                    order[OrderPossitionIndex] = index + 1;
                });
                ordersWithSameLoadAndPosition.forEach((order, index) => {
                    if (order.id === o.id) {
                        o[OrderPossitionIndex] = order[OrderPossitionIndex];
                    }
                })

                }
            });

            // case with existing order in load and NOT
            const load_id = state.loadType === 'inbound' ? 'loadId' : 'outboundLoadId';
            const ordersInLoad = newOrders.filter(order => order[load_id] === loadId);
            const assignedOrdersIds = orders.map(order => order.id);
            const ordersInLoadBeforeAssign = ordersInLoad.filter(order => !assignedOrdersIds.includes(order.id));

            if (locationObjects?.length) {
                locationObjects.forEach(locationObject => {
                    // case automatic selected appt and status
                    const ordersInLoadWithSameLoc = ordersInLoadBeforeAssign.filter(order => order[location] === locationObject[location])[0];
                    const selectedLocation = locationObject[location];
                    if (ordersInLoadBeforeAssign.filter(order => order[location] === selectedLocation)) {
                        if (ordersInLoadWithSameLoc) {
                            newOrders.forEach(order => {
                                if (order.id === ordersInLoadWithSameLoc.id) {
                                    order[pickAppointment] = locationObject.checkedAppointment;
                                    order[pickAppointmentStatus] = locationObject.checkedStatus;
                                }
                            })
                        }
                    }
                    newOrders.forEach(order => {
                        locationObjects.forEach(locationObject => {
                            if (order[location] === locationObject[location] && locationObject.checkedAppointment) {
                                order[pickAppointment] = locationObject.checkedAppointment;
                                order[pickAppointmentStatus] = locationObject.checkedStatus;
                            }
                        })
                    })
                    
                })

                if (!openModal) {
                    newOrders.forEach(order => {
                        locationObjects.forEach(locationObject => {
                            if (order?.[location] === locationObject?.[location] && 
                                order[orderLoadId] === loadId && 
                                locationObject.checkedAppointment &&
                                !order[pickAppointment]) {
                                    order[pickAppointment] = locationObject.checkedAppointment;
                                    order[pickAppointmentStatus] = locationObject.checkedStatus;
                            }
                        })
                    })
                }

            } else { //assignOrdersWithoutModal
                orders.forEach(assignedOrder => {
                    if (!openModal) {
                        const thereIsOrderWithSameLocation = ordersInLoadBeforeAssign.filter(order => order[location] === assignedOrder[location]).length > 0;
                        const orderWithSameLocation = ordersInLoadBeforeAssign.filter(order => order[location] === assignedOrder[location])[0];
                        const ordersWithSameLocation = ordersInLoadBeforeAssign.filter(order => order[location] === assignedOrder[location]);
                        const ordersWithSameLocInAssigned = orders.filter(order => order[location] === assignedOrder[location]);

                        if (thereIsOrderWithSameLocation) {
                            if (!orderWithSameLocation[pickAppointment]) {
                                newOrders.forEach(order => {
                                    if (order.id === assignedOrder.id) {
                                        ordersWithSameLocation.forEach(o => {
                                            order[pickAppointment] = assignedOrder[pickAppointment];
                                            order[pickAppointmentStatus] = assignedOrder[pickAppointmentStatus];
                                        })
                                    }
                                })
                            }
                        } else {
                            newOrders.forEach(order => {
                                if (order.id === assignedOrder.id) {
                                    ordersWithSameLocInAssigned.forEach(o => {
                                        order[pickAppointment] = o[pickAppointment];
                                        order[pickAppointmentStatus] = o[pickAppointmentStatus];
                                    })
                                }
                            })
                        }
                    }
                })
            }

            // inboundPage all delAppts should be the same
            const orderInboundDropAppointment = ordersInLoad.filter(order => !assignedOrdersIds.includes(order.id))[0]?.inboundDropAppointment;
            const orderDropInboundAppointment = ordersInLoad.filter(order => !assignedOrdersIds.includes(order.id))[0]?.orderDropInboundAppointment;
            const orderDelAppointmentStatus = ordersInLoad.filter(order => !assignedOrdersIds.includes(order.id))[0]?.dropInboundAppointmentStatus;
            const orderInLoadWithPickAppt = ordersInLoadBeforeAssign.filter(order => order.inboundPickAppointment);
            // if the page is INBOUND and all orders in inboundDropAppointment are null
            if (state.loadType === 'inbound' && ordersInLoadBeforeAssign?.length && !ordersInLoadBeforeAssign[0].inboundDropAppointment) {
                orders.forEach(assignedOrder => {
                    newOrders.forEach(order => {
                        if (order.id === assignedOrder.id) {
                            order.inboundDropAppointment = null;
                            order.dropInboundAppointmentStatus = null;
                        }
                    })
                })
            } else {
                orders.forEach(assignedOrder => {
                    newOrders.forEach(order => {
                        if (order.id === assignedOrder.id) {
                            if (orderInboundDropAppointment) {
                                order.inboundDropAppointment = orderInboundDropAppointment;
                                order.dropInboundAppointmentStatus = orderDelAppointmentStatus;
                            } else {
                                if (ordersInLoad.length > 0) {
                                    ordersInLoad.forEach(orderInLoad => {
                                        newOrders.forEach(order => {
                                            if (orderInLoad.id === order.id) {
                                                order.inboundDropAppointment = assignedOrder.inboundDropAppointment;
                                                order.dropInboundAppointmentStatus = assignedOrder.dropInboundAppointmentStatus;
                                            }
                                        })
                                    })
                                }
                            }
                        }
                    })
                })
            }
            // if the page is INBOUND and all orders in inboundPickAppointment are null
            if (state.loadType === 'inbound' && !orderInLoadWithPickAppt.length) {
                orders.forEach(assignedOrder => {
                    newOrders.forEach(order => {
                        if (order.id === assignedOrder.id) {
                            order.inboundPickAppointment = assignedOrder.inboundPickAppointment;
                        }
                    })
                })
            }

            keyNumber++;
        });
    }
    else {
        orders.forEach(assignedOrder => {
            const ordersWithSameLocInAssigned = orders.filter(order => order[location] === assignedOrder[location]);
            newOrders.forEach(order => {
                if (order.id === assignedOrder.id) {
                    ordersWithSameLocInAssigned.forEach(o => {
                        order[pickAppointment] = o[pickAppointment];
                        order[pickAppointmentStatus] = o[pickAppointmentStatus];
                    })
                }
            })
        })        
        newOrders.forEach(o => {
            if (assignedOrderObjects.findIndex(unaO => unaO.id === o.id) !== -1) {
                o[state.loadIdField] = loadId;
                if (state.loadType === 'inbound') {
                    o.inLoad = loadNum;
                }
                else {
                    o.outLoad = loadNum;
                }
            }
        });

        // reOrdering/reArranging loadOrders (by OrderPossitionIndex), createNewLoad
        newOrders.forEach(ord => {
            if (assignedOrderObjects.findIndex(unaO => unaO.id === ord.id) !== -1) {
                const OrderPossitionIndex = state.loadType === 'inbound' ? 'inOrderPossitionIndex' : 'outOrderPossitionIndex';
                const locationId = state.loadType === 'inbound' ? 'pickLocationId' : 'dropLocationId';
                const load_id = state.loadType === 'inbound' ? 'loadId' : 'outboundLoadId';
                const ordersWithSameLoadAndPosition = newOrders.filter(order => order[load_id] === ord[load_id] && order[locationId] === ord[locationId]);
                
                ordersWithSameLoadAndPosition.forEach((order, index) => {                    
                    if (order.id === ord.id) {
                        ord[OrderPossitionIndex] = index + 1;
                    }
                })
            }
        })
    }

    return updateOrdersLoadState(state, newOrders, newLoads, state.subOrders, false, '', true, '', openModal);
};

export const assignOrdersToNewLoadWithModal = (state, orders, openModalNewLoad) => {
    const assignedOrders = orders;
    return updateOrdersLoadState(state, state.orders, state.loads, state.subOrders, false, '', true, '', false, openModalNewLoad, assignedOrders);
};

export const addNewLoad = (state, loadData) => {
    const newLoads = [...state.loads];
    newLoads.push(loadData);
    return updateOrdersLoadState(state, state.orders, newLoads, state.subOrders);
};

export const assignOrdersToNewLoad = (state, orders, loadData) => {
    const newLoads = [...state.loads];
    newLoads.push(loadData);
    return assignOrdersToLoadObject(state, loadData.id, orders, newLoads, loadData.loadNum);
};

export const createSubLoadAssignOrders = (state, orders, loadData, dropLocationId) => {
    const newLoads = [...state.loads];
    newLoads.push(loadData);
    const addedSubOrders = [];
    //const 

    orders.forEach(o => {
        addedSubOrders.push({
            orderId: o.rowState.orderData.id,
            dropLocationId,
            loadId: loadData.id,
            dropLocation: 'Need to define' //???
        });
    });

    const newSuborders = [...state.subOrders, ...addedSubOrders];


    return updateOrdersLoadState(state, state.orders, newLoads, newSuborders);
};

export const collectDeletedSubLoads = (loads, deletedLoads, deletedLoadId) =>
    loads.filter(sl => sl.parentLoadId === deletedLoadId).forEach(dl => {
        deletedLoads.push(dl.id);
        collectDeletedSubLoads(loads, deletedLoads, dl.id);
    });

export const deleteLoad = (state, rowState) => {
    const unAssignedOrderObjects = state.orders.filter(o => o[state.loadIdField] === rowState.loadData.id);
    const deletedLoads = [rowState.loadData.id];
    collectDeletedSubLoads(state.loads, deletedLoads, rowState.loadData.id);
    const newLoads = state.loads.filter(l => !deletedLoads.includes(l.id));
    const newSubOrders = state.subOrders.filter(so => deletedLoads.indexOf(so.loadId) === -1);

    // reOrdering/reArranging loadOrders (by OrderPossitionIndex)
    const newOrders = [...state.orders];
    const OrderPossitionIndex = state.loadType === 'inbound' ? 'inOrderPossitionIndex' : 'outOrderPossitionIndex';
    const locationId = state.loadType === 'inbound' ? 'pickLocationId' : 'dropLocationId';
    const load_id = state.loadType === 'inbound' ? 'loadId' : 'outboundLoadId';
    newOrders.forEach(o => {
        if (o[load_id] === rowState.loadData.parentLoadId) {
            const ordersWithSameLoadAndPosition = newOrders.filter(order => order[load_id] === o[load_id] && order[locationId] === o[locationId]);
            ordersWithSameLoadAndPosition.forEach((order, index) => {
                order[OrderPossitionIndex] = index +1 ;
            });
        }
    });    
    
    unassignOrderObjects(state, unAssignedOrderObjects, newLoads, newSubOrders);
    return updateOrdersLoadState(state, newOrders, newLoads, newSubOrders);
};

export const updateLoadOrderGroupData = (state, loadId, order, header, newValue, newOrders) => {
    const orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadId);
    if (orderLoad) {
        let key;
        if (header?.pickGroup) {
            key = orderLoad.rowState.createPickKey;
        }
        else if (header?.dropGroup) {
            key = orderLoad.rowState.createDropKey;
        }
        if (key) {
            const orderGroups = orderLoad.rowState.groups(orderLoad.ordersData, key);
            const foundGroupKey = orderLoad.rowState.findOrderGroup(orderGroups, order);
            const foundGroup = orderGroups[foundGroupKey];
            foundGroup.forEach(go => {
                const changedOrder = newOrders.find(o => o.id === go.id);
                if (changedOrder) {
                    if (header?.key === 'readyDate' && !newValue) {
                        changedOrder['puDate'] = '';
                    }
                    changedOrder[header?.key] = newValue;
                }
            });
        }
    }
};

export const updateLoadOrders = (state, loadId, order, header, newValue, newOrders, isSub) => {
    const orderLoad = state.loadsAssignedOrders.find(l => l.rowState.type === 'load' && l.rowState.loadData.id === loadId);
    const load_id = state.loadType === 'inbound' || isSub ? 'loadId' : 'outboundLoadId';
    const changedOrder = isSub ? newOrders.find(o => o.id === order.subOrderId) : newOrders.find(o => o.id === order.id);
    
    if (orderLoad && state.loadType === 'inbound') {
        // pick appointment and status should be updated according to the pick location (should be the same for the same locations.)
        const orderInLoads = newOrders.filter(order => order[load_id] === loadId);
        const ordersWithSamePickLocation = newOrders.filter(order => order[load_id] === loadId && order.pickLocationId === changedOrder.pickLocationId)
        ordersWithSamePickLocation.forEach(order => {
            order.inboundAppointmentStatus = changedOrder.inboundAppointmentStatus;
            order.inboundPickAppointment = changedOrder.inboundPickAppointment;
        });
        // del appointment and status should be the same as they are being delivered to the same location.
        orderInLoads.forEach(order => {
            order.dropInboundAppointmentStatus = changedOrder.dropInboundAppointmentStatus;
            order.inboundDropAppointment = changedOrder.inboundDropAppointment;
        });
    } else if (orderLoad && state.loadType === 'outbound') {
        // pick appointment and status should be the same as they are taken from the same location
        const orderInLoads = newOrders.filter(order => order[load_id] === loadId);
        orderInLoads.forEach(order => {
            order.pickOutboundAppointmentStatus = changedOrder.pickOutboundAppointmentStatus;
            order.outboundPickAppointment = changedOrder.outboundPickAppointment;
            if (isSub) {
                order.pickAppointmentDate = changedOrder.pickAppointmentDate;
                order.pickAppointmentStatus = changedOrder.pickAppointmentStatus;
            }
        });
        // del appointment and status should be updated according to the pick location (should be the same for the same locations.)
        const ordersWithSameDropLocation = newOrders.filter(order => order[load_id] === loadId && order.dropLocationId === changedOrder.dropLocationId)
        ordersWithSameDropLocation.forEach(order => {
            order.outboundAppointmentStatus = changedOrder.outboundAppointmentStatus;
            order.outboundDropAppointment = changedOrder.outboundDropAppointment;
        });
    }
};

export const setLoadData = (state, newValue, rowState, header) => {
    const newLoads = [...state.loads];
    const changedLoad = newLoads.find(o => o.id === rowState.loadData.id);
    if (header.key === 'wk') {
        const oldWeek = changedLoad['pickWeek'];
        changedLoad['pickWeek'] = newValue;
        changedLoad['loadNumber'] = changedLoad['loadNumber'].replace(`WK- ${oldWeek}`, `WK- ${newValue}`);
    }
    else {
        changedLoad[header.key] = newValue;
    }
    
    return updateOrdersLoadState(state, state.orders, newLoads, state.subOrders);
};

export const setData = (state, newValue, rowState, header) => {
    if (rowState.orderData) {
        if (rowState.isSub) {
            //sub order set
            const newSubOrders = [...state.subOrders];
            const changedSubOrder = newSubOrders.find(o => o.id === rowState.orderData.subOrderId);
            if (changedSubOrder) {
                if (header?.key === 'readyDate' && !newValue) {
                    changedSubOrder['puDate'] = '';
                }
                Array.isArray(newValue) && newValue.forEach(item => {
                    changedSubOrder[SubOrderFieldConvertor(state)[item.header]] = item.value;
                });
                if (header?.key === 'dropLocation') {
                    changedSubOrder['dropLocationId'] = newValue;
                } else {
                    changedSubOrder[header?.key] = newValue;
                }
            }
            updateLoadOrders(state, rowState?.loadObjectId, rowState.orderData, header, newValue, newSubOrders, rowState.isSub);
            return updateOrdersLoadState(state, state.orders, state.loads, newSubOrders);
        }
        else {
            //order set
            const newOrders = [...state.orders];
            const changedOrder = newOrders.find(o => o.id === rowState.orderData.id);

            // update data (Appt) for hardBatch orders as well
            const orderGroupIndex = changedOrder?.orderGroupIndex;
            const hardBatchOrders = newOrders
                .filter(order => order?.orderGroupIndex === orderGroupIndex && order?.batchType === 'Hard');
            
            // update data (location) for subOrders which are subOrders of current hardBatchOrders
            const subOrders = [...state.subOrders];
            const subOrdersOfHardOrder = subOrders.filter(order => order.orderId === changedOrder.id);

            let hardBatchSubOrders = [];
            for (let i = 0; i < hardBatchOrders.length; i++) {
                for (let j = 0; j < subOrdersOfHardOrder.length; j++) {
                    if (hardBatchOrders[i].id === subOrdersOfHardOrder[j].orderId) {
                        hardBatchSubOrders.push(subOrdersOfHardOrder[j])
                    }
                }
            }

            if (hardBatchOrders.length > 0 && header?.key === 'dropLocation') {
                hardBatchOrders.forEach(order => {
                    if (header?.key === 'dropLocation') {
                        order['dropLocationId'] = newValue;
                    } else {
                        order[header?.key] = newValue;
                    }
                    Array.isArray(newValue) && newValue.forEach(item => {
                        order[item.header] = item.value;
                    });
                })
                // for subOrders for hardBatchOrder
                if (hardBatchSubOrders.length) {
                    hardBatchSubOrders.forEach(order => {
                        if (header?.key === 'dropLocation') {
                            order['dropLocationId'] = newValue;
                        } else {
                            order[header?.key] = newValue;
                        }
                    })
                }
            } else if (changedOrder) {
                if (header?.key === 'readyDate' && !newValue) {
                    changedOrder['puDate'] = '';
                    changedOrder[header?.key] = newValue;
                } else if ((header?.key === 'palletCount' || header?.key === 'cases' || header?.key === 'weight' || header?.key === 'space') && newValue !== 'unColor') {
                    changedOrder['orderHistoryDict'][header?.key] = true;
                    changedOrder['outOrderHistoryDict'][header?.key] = true;
                    changedOrder[header?.key] = newValue;
                } else if (header?.key === 'dropLocation') {
                    changedOrder['dropLocationId'] = newValue;
                } else if (newValue === 'unColor') {
                    changedOrder['orderHistoryDict'][header?.key] = false;
                    changedOrder['outOrderHistoryDict'][header?.key] = false;
                } else {
                    changedOrder[header?.key] = newValue;
                }
                Array.isArray(newValue) && newValue.forEach(item => {
                    changedOrder[item.header] = item.value;
                });
            }
            updateLoadOrders(state, rowState?.loadData?.id, rowState.orderData, header, newValue, newOrders);
            updateLoadOrderGroupData(state, rowState?.loadData?.id, rowState.orderData, header, newValue, newOrders);
            return updateOrdersLoadState(state, newOrders, state.loads, state.subOrders);
        }
    }
    else {
        //load set
        //??? todo
        return state;
    }
};

export const setDataEfs = (state, newValue, rowState, header) => {
    if (rowState.orderData) {
        const newEfs = [...state.orders];
        const changedEfs = newEfs.find(o => o.id === rowState.orderData.id);
        if (changedEfs) {
            changedEfs[header.key] = newValue;
            if (state.loadType === 'inbound') {
                changedEfs.inPaymentMethod = newValue.paymentMethod;
                changedEfs.inAmount = newValue.amount;
                changedEfs.inCardNumber = newValue.cardNumber;
                changedEfs.inNote = newValue.note;
                changedEfs.efsInId = newValue.id;
                changedEfs.inEfsCreateDate = changedEfs.inEfsCreateDate ? changedEfs.inEfsCreateDate : Date.now();
            }
            else if (state.loadType === 'outbound') {
                changedEfs.outPaymentMethod = newValue.paymentMethod;
                changedEfs.outAmount = newValue.amount;
                changedEfs.outCardNumber = newValue.cardNumber;
                changedEfs.outNote = newValue.note;
                changedEfs.efsOutId = newValue.id;
                changedEfs.outEfsCreateDate = changedEfs.outEfsCreateDate ? changedEfs.outEfsCreateDate : Date.now();
            }
        }
        updateLoadOrderGroupData(state, rowState?.loadData?.id, rowState.orderData, header, newValue, newEfs);
        return updateOrdersLoadState(state, newEfs, state.loads, state.subOrders);
        
    }
    else {
        //load set
        //??? todo
        return state;
    }
};

export const setDisplayState = (state, rowState, header, enable) => ({
    ...state, ...{
        lastSelected: {
            id: rowState.id,
            headerKey: header.key,
            enable: enable
        }
    }
});

export const updateLoadData = (state, newData) => {

    const newLoads = [...state.loads];
    const changedLoad = newLoads.find(l => l.id === newData.id);
    changedLoad.driverName = newData.driverName;
    changedLoad.color = newData.color;
    changedLoad.target = newData.target;
    changedLoad.driverPhone = newData.driverPhone;
    changedLoad.rate = newData.rate;
    changedLoad.truckNumber = newData.truckNumber;
    changedLoad.carrierName = newData.carrierName;
    changedLoad.trailer = newData.trailer;
    changedLoad.carrierId = newData.carrierId;
    changedLoad.truckId = newData.truckId;
    changedLoad.notes = newData.notes;
    changedLoad.efsSum = newData.efsSum;
    changedLoad.efsStatusMin = newData.efsStatusMin;
    return updateOrdersLoadState(state, state.orders, newLoads, state.subOrders);
};

export const setAddNewInInProcess = (state, condition) => ({
    ...state,
    ...{
        addNewInProcess: condition
    }
});

export const setRefreshInProcess = (state, condition) => ({
    ...state,
    ...{
        refreshInProcess: condition
    }
});

export const setCreateInProcess = (state, condition) => ({
    ...state,
    ...{
        createInProcess: condition
    }
});

export const setCreateSubInProcess = (state, condition) => ({
    ...state,
    ...{
        createSubInProcess: condition
    }
});

export const resetError = state => ({
    ...state,
    ...{
        hasError: false,
        message: ''
    }
});

export const setInLoading = state => ({
    ...state, ...{
        loading: true
    }
});

export const setSortLoadsByCarrier = (state, sort) => {
    const sortedLoads = state.loads.sort((loadA, LoadB) => sort ? 
        loadA?.carrierName?.localeCompare(LoadB.carrierName) :
        LoadB?.carrierName?.localeCompare(loadA.carrierName)
    )
    return updateOrdersLoadState(state, state.orders, sortedLoads, state.subOrders, state.hasError, state.message, sort, 'carrier');
};

export const setSortLoadsByPickAppts = (state, sort) => {
    const loads = state.loadsAssignedOrders.filter(order => order.rowState.type === 'load');

    const appts = state.loadType === 'inbound' ? 'inboundPickAppointment' : state.loadType === 'outbound' ? 'outboundPickAppointment' : '';
    let loadWithSortedOrdersEmpty = [];
    let loadWithSortedOrdersFilled = [];
    for (let i = 0; i < loads.length; i++) {
        if (loads[i].ordersData.length === 0) {
            loadWithSortedOrdersEmpty.push(loads[i].rowState.loadData);
        } else {
            let temp = loads[i].ordersData.sort((a, b) => new Date(b.rowState.orderData[appts]) - new Date(a.rowState.orderData[appts]));
            loadWithSortedOrdersFilled.push(temp)
        }
    };

    let joinedLoadWithSortedOrders = [];

    if (sort) {
        loadWithSortedOrdersFilled.sort((a, b) => new Date(a[0]?.rowState.orderData[appts]) - new Date(b[0]?.rowState.orderData[appts]))
        joinedLoadWithSortedOrders = [...loadWithSortedOrdersFilled, ...loadWithSortedOrdersEmpty];
    } else {
        loadWithSortedOrdersFilled.sort((a, b) => new Date(b[0]?.rowState.orderData[appts]) - new Date(a[0]?.rowState.orderData[appts]))
        joinedLoadWithSortedOrders = [...loadWithSortedOrdersEmpty, ...loadWithSortedOrdersFilled];
    };

    const result = joinedLoadWithSortedOrders.map(load => load[0]?.rowState ? load[0]?.rowState.loadData : load);
    return updateOrdersLoadState(state, state.orders, result, state.subOrders, state.hasError, state.message, sort, 'pickAppts');
};

export const setSortLoadsByPickLocation = (state, sort) => {
    const loads = state.loadsAssignedOrders.filter(order => order.rowState.type === 'load');

    let loadWithSortedOrdersEmpty = [];
    let loadWithSortedOrdersFilled = [];
    for (let i = 0; i < loads.length; i++) {
        if (loads[i].ordersData.length === 0) {
            loadWithSortedOrdersEmpty.push(loads[i]);
        } else {
            loadWithSortedOrdersFilled.push(loads[i].ordersData);
        }
    }

    let joinedLoadWithSortedOrders = [];

    if (sort) {
        loadWithSortedOrdersFilled.sort((a, b) => a[0]?.rowState?.orderData.pickLocation.localeCompare(b[0]?.rowState?.orderData.pickLocation));
            joinedLoadWithSortedOrders = [...loadWithSortedOrdersFilled, ...loadWithSortedOrdersEmpty];
    } else {
        loadWithSortedOrdersFilled.sort((a, b) => b[0]?.rowState?.orderData.pickLocation.localeCompare(a[0]?.rowState?.orderData.pickLocation));
            joinedLoadWithSortedOrders = [...loadWithSortedOrdersEmpty, ...loadWithSortedOrdersFilled];
    }

    const result = joinedLoadWithSortedOrders.map(load => load[0]?.rowState ? load[0]?.rowState.loadData : load);

    return updateOrdersLoadState(state, state.orders, result, state.subOrders, state.hasError, state.message, sort, 'pickLocation');
};

export const setSortLoadsByDropLocation = (state, sort) => {
    const loads = state.loadsAssignedOrders.filter(order => order.rowState.type === 'load');

    let loadWithSortedOrdersEmpty = [];
    let loadWithSortedOrdersFilledSanFernanto = [];
    let loadWithSortedOrdersFilledOther = [];
    let joinedLoadWithSortedOrders = [];

    for (let i = 0; i < loads.length; i++) {
        if (loads[i].ordersData.length === 0) {
            loadWithSortedOrdersEmpty.push(loads[i]);
        } else if (loads[i].ordersData.find(order => order.rowState.orderData.routeId === 2)) {
            loadWithSortedOrdersFilledSanFernanto.push(loads[i].ordersData);
        } else {
            loadWithSortedOrdersFilledOther.push(loads[i].ordersData);
        }
    }

    if (sort) {
        loadWithSortedOrdersFilledOther.sort((a, b) => a[a.length - 1]?.rowState?.orderData.dropLocation.localeCompare(b[a.length - 1]?.rowState?.orderData.dropLocation));
        joinedLoadWithSortedOrders = [...loadWithSortedOrdersEmpty, ...loadWithSortedOrdersFilledSanFernanto, ...loadWithSortedOrdersFilledOther];
    } else {
        loadWithSortedOrdersFilledOther.sort((a, b) => b[a.length - 1]?.rowState?.orderData.dropLocation.localeCompare(a[a.length - 1]?.rowState?.orderData.dropLocation));
        joinedLoadWithSortedOrders = [...loadWithSortedOrdersFilledOther, ...loadWithSortedOrdersFilledSanFernanto, ...loadWithSortedOrdersEmpty];
    }

    const result = joinedLoadWithSortedOrders.map(load => load[0]?.rowState ? load[0]?.rowState.loadData : load);
    return updateOrdersLoadState(state, state.orders, result, state.subOrders, state.hasError, state.message, sort, 'dropLocation');
};

export const setSortLoadsByDeliverNumber = (state, sort) => {
    const sortedLoads = state.loads; // TODO
    // const sortedLoads = state.loads.sort((loadA, LoadB) => {
    // need to clarify
    // })
    return updateOrdersLoadState(state, state.orders, sortedLoads, state.subOrders, state.hasError, state.message, sort, 'deliverNumber');
};

export const filterInOutOrders = (state, data) => {
    const { filterType, option} = data;
    const stateFilterOptions = JSON.parse(JSON.stringify(state.filterOptions));
    Object.keys(stateFilterOptions).forEach(key => {
        if (key === filterType) {
            stateFilterOptions[key] = option;
        } else if (filterType === 'default') {
            stateFilterOptions[key] = 'All';
        }
    });

    return {
        ...state,
        ...{
            filterOptions: stateFilterOptions,
        },
    }
};

export const getHistoryList = (state, response) => {
    const data = response.data.data || [];
    return {
        ...state,
        historyList: data,
        historyLoading: false,
    }
};

export const setHistoryLoading = state => ({
    ...state, ...{
        historyLoading: true
    }
});

export const setReOrderLoad = (state, data) => {
    const { firstOrderId, secondOrderId } = data;
    const newOrders = [...state.orders];
    const orderPossitionIndex = state.loadType === 'inbound' ? 'inOrderPossitionIndex': 'outOrderPossitionIndex';

    const firstOrder = newOrders.find(order => order.id === firstOrderId);
    const secondOrder = newOrders.find(order => order.id === secondOrderId);    

    const firstOrderIndex = firstOrder?.[orderPossitionIndex];
    const secondOrderIndex = secondOrder?.[orderPossitionIndex];

    firstOrder[orderPossitionIndex] = secondOrderIndex;
    secondOrder[orderPossitionIndex] = firstOrderIndex;

    return updateOrdersLoadState(state, newOrders, state.loads, state.subOrders);
};